import React from 'react'
import SEO from '~/components/seo'
import Cart from '~/components/Cart'
import { Container } from '~/utils/styles'
import { BrowserView, MobileView } from 'react-device-detect'
import '~/utils/effect.css'

const CartPage = props => {
  return (
    <div>
      <BrowserView>
        <SEO
          title="BDST | BY BEN TAVERNITI | Bureau de Stil"
          keywords={[
            `bureau de Stil`,
            `ben tavertini`,
            `kim kardashian`,
            'LA Fashion',
            'fashion',
            'joyce bonelli',
            'unravel project',
            'trend brand',
            'online shop',
          ]}
        />
        <Cart />
      </BrowserView>
      <MobileView>
        <SEO
          title="Bureau de Stil | BY BEN TAVERNITI"
          keywords={[
            `bureau de Stil`,
            `ben tavertini`,
            `kim kardashian`,
            'LA Fashion',
            'fashion',
            'joyce bonelli',
            'unravel project',
            'trend brand',
            'online shop',
          ]}
        />
        <Cart />
      </MobileView>
    </div>
  )
}

export default CartPage
