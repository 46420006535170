import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import StoreContext from '~/context/StoreContext'
import LineItem from './LineItem'
import { BrowserView, MobileView } from 'react-device-detect'
import {
  CardProductTitle,
  CardProductTotal,
  BlackHeader,
  ProceedButton,
  ProceedButtonMobile,
  ProceedDetails,
  ProceedDetailsMobile,
  LineItemSubtotal,
  TotalPrice,
  Column1,
  Column2,
  Column4,
  LeftMobile,
  RightMobile,
  CartCounter,
  CartCounterMobile,
  BlackHeaderMobile,
  TouchPointMenu,
  Close,
} from './LineItem/styles'
import {
  CartFooter,
  CartLink,
  CheckoutButtonMobile,
} from '../Navigation/styles'
import Navigation from '../Navigation'
import { GeneralGrid, GeneralGridMobileProduct } from '~/utils/styles'
import '../../effects/Effects.css'
import CloseWhite from '../../images/close_white.png'

const countQuantity = lineItems => {
  let quantity = 0

  lineItems.forEach(item => {
    quantity = quantity + item.quantity
  })

  return quantity
}

const Cart = props => {
  const {
    removeLineItem,
    store: { checkout, adding },
  } = useContext(StoreContext)
  const [quantity, setQuantity] = useState(
    countQuantity(checkout ? checkout.lineItems : [])
  )
  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  const [loading, setLoading] = useState(true)
  const [totalPrice, setTotalPrice] = useState()
  const [css, setCss] = useState('slide-in-bottom')
  const [cartPage, setCart] = useState(false)

  useEffect(() => {
    setQuantity(countQuantity(checkout ? checkout.lineItems : []))
  }, [adding, quantity])

  const handleRemove = (client, checkout_id, line_item_id, amount) => {
    removeLineItem(client, checkout_id, line_item_id)
    var newAmount = quantity - parseInt(amount)
    setQuantity(newAmount)
    setTotalPrice(checkout.totalPrice)
  }

  const line_items = checkout.lineItems.map(line_item => {
    return (
      <LineItem
        key={line_item.id.toString()}
        handleRemove={handleRemove}
        line_item={line_item}
      />
    )
  })
  useEffect(() => {
    if (line_items.length > 0) {
      setLoading(false)
      setTotalPrice(checkout.totalPrice)
    } else {
      setQuantity(0)
      setTotalPrice(0)
    }
  }, [line_items, checkout.totalPrice, quantity])

  const closePage = () => {
    setCss('slide-out-bottom')
    setTimeout(() => window.history.back(), 1000)
  }

  const getPrice = price =>
    Intl.NumberFormat(undefined, {
      currency: checkout.currencyCode ? checkout.currencyCode : 'USD',
      minimumFractionDigits: 2,
      style: 'currency',
    }).format(parseFloat(price ? price : 0))

  useEffect(() => {
    const pathArray = window.location.pathname.split('/')

    if (pathArray[1] === 'cart') {
      setCart(true)
    } else {
      setCart(false)
    }
  }, [])

  return (
    <div className={css}>
      {loading ? null : (
        <BrowserView>
          <GeneralGrid>
            <BlackHeader />
            <CardProductTitle>Shopping Cart</CardProductTitle>
            <CardProductTotal>
              {quantity}

              {quantity >= 1 || 0 ? ' ITEMS' : ' ITEM'}
            </CardProductTotal>

            <Close onClick={() => closePage()}>Close</Close>
          </GeneralGrid>

          <hr />
          {line_items}
          <GeneralGrid style={{ margin: '0 0 50px 0' }}>
            <Column1>
              <hr style={{ width: '120%' }} />
            </Column1>

            <Column2>
              <hr style={{ width: '320%' }} />
              <LineItemSubtotal>SUBTOTAL</LineItemSubtotal>
            </Column2>

            <Column4>
              <hr />
              <TotalPrice>
                USD{quantity === 0 ? '$0,00' : getPrice(totalPrice)}
              </TotalPrice>
            </Column4>
            <ProceedDetails>
              Shipping and taxes calculated at checkout
            </ProceedDetails>
            <ProceedButton
              onClick={handleCheckout}
              disabled={checkout.lineItems.length === 0}
            >
              Proceed to Checkout
            </ProceedButton>
          </GeneralGrid>
        </BrowserView>
      )}

      <MobileView>
        <BlackHeaderMobile>
          {' '}
          <CartCounterMobile> Cart [{quantity}]</CartCounterMobile>
          <img
            style={{
              position: 'fixed',
              right: '16px',
              top: '10px',
              width: '12px',
            }}
            src={CloseWhite}
            onClick={() => window.history.back()}
          />
          <TouchPointMenu onClick={() => window.history.back()} />
        </BlackHeaderMobile>

        {line_items}
        <GeneralGridMobileProduct>
          <hr />
          <LeftMobile>
            <LineItemSubtotal>SUBTOTAL</LineItemSubtotal>
          </LeftMobile>
          <RightMobile>
            <TotalPrice>
              USD{quantity === 0 ? '$0,00' : getPrice(totalPrice)}
            </TotalPrice>
          </RightMobile>

          <LeftMobile>
            <ProceedButtonMobile
              onClick={handleCheckout}
              disabled={checkout.lineItems.length === 0}
            >
              Proceed to Checkout
            </ProceedButtonMobile>
            <ProceedDetailsMobile>
              Shipping and taxes calculated at checkout
            </ProceedDetailsMobile>
          </LeftMobile>
        </GeneralGridMobileProduct>
      </MobileView>
    </div>
  )
}

export default Cart
