import styled from '@emotion/styled'
import Image from 'gatsby-image'

export const Wrapper = styled.div`
  margin: 12px 0 100px 350px;
`

export const Column1 = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`

export const Column2 = styled.div`
  grid-column-start: 3;
  grid-column-end: 5;
`

export const Column3 = styled.div`
  grid-column-start: 3;
  grid-column-end: 5;
  margin: -180px 0 0 0;
`

export const Column4 = styled.div`
  grid-column-start: 7;
  grid-column-end: 7;
`

export const Column5 = styled.div`
  grid-column-start: 7;
  grid-column-end: 7;
  margin: -45px 0 0 0;
`

export const ProceedButton = styled.div`
  grid-column-start: 6;
  grid-column-end: 8;
  background: black;
  color: white;
  text-align: center;
  position: relative;
  top: 2%;
  height: 42px;
  -webkit-appearance: none;
`
export const ProceedButtonMobile = styled.div`
  grid-column-start: 6;
  grid-column-end: 8;
  background: black;
  color: white;
  text-align: center;
  height: 42px;
  width: 200%;
  -webkit-appearance: none;
`
export const ProceedDetails = styled.div`
  grid-column-start: 3;
  grid-column-end: 6;
`

export const ProceedDetailsMobile = styled.div`
  width: 200%;
  margin: 42px 0 42px 0;
`

export const LineItemTitle = styled.div`
  font-family: 'BDST';
  letter-spacing: 0.05em;
  font-size: 30px;
`
export const LineItemOptions = styled.div`
  font-family: 'BDST';
  font-size: 30px;
  line-height: 42px;
`
export const LineItemSubtotal = styled.div`
  font-family: 'BDST';
  letter-spacing: 0.05em;
  font-size: 30px;
  margin: 0 0 50px 0;
`

export const LineItemPrice = styled.div`
  font-family: 'BDST';
  letter-spacing: 0.05em;
  font-size: 30px;
  text-align: right;
`

export const TotalPrice = styled.div`
  font-family: 'BDST';
  letter-spacing: 0.05em;
  font-size: 30px;
  text-align: right;
  margin: 0 0 50px 0;
`

export const CardProductTitle = styled.div`
  font-family: 'BDST';
  letter-spacing: 0.05em;
  font-size: 30px;
  color: black;
  width: 230px;
  grid-column-start: 1;
  grid-column-end: 2;
`
export const CardProductTotal = styled.div`
  font-family: 'BDST';
  letter-spacing: 0.05em;
  grid-column-start: 3;
  grid-column-end: 4;
  font-size: 30px;
  line-height: 42px;
  color: black;
  width: 230px;
`
export const Close = styled.a`
  font-family: 'BDST';
  letter-spacing: 0.05em;
  font-size: 30px;
  color: black;
  grid-column-start: 6;
  grid-column-end: 8;
  text-align: right;
  cursor: pointer;
`

export const BlackHeader = styled.div`
  background: black;
  grid-column-start: 1;
  grid-column-end: 8;
  height: 42px;
  margin: -16px 0 0 -16px;
`
export const BlackHeaderMobile = styled.div`
  background: black;
  grid-column-start: 1;
  grid-column-end: 7;
  height: 42px;
  width: 100%;
  z-index: 5000;
  margin: 42px 0 0 0;
`

export const CardProductText = styled.div`
  font-size: 12px;
  line-height: 42px;
  font-family: 'LTUnivers-BasicRegular';
  font-weight: 100;
  display: flex;
`

export const CardProductTextMobile = styled.div`
  font-size: 12px;
  line-height: 42px;
  font-family: 'LTUnivers-BasicRegular';
  font-weight: 100;
  display: flex;
`

export const CardProductButton = styled.a`
  font-size: 12px;
  line-height: 42px;
  font-family: 'LTUnivers-BasicRegular';
  font-weight: 100;
  cursor: pointer;
  -webkit-appearance: none;
`

export const CardProductButtonMobile = styled.a`
  font-size: 12px;
  line-height: 42px;
  font-family: 'LTUnivers-BasicRegular';
  font-weight: 100;
  cursor: pointer;
  -webkit-appearance: none;
`

export const CardProductTextRight = styled.div`
  font-size: 12px;
  line-height: 42px;
  font-family: 'LTUnivers-BasicRegular';
  font-weight: 100;
  text-align: right;
`

export const CardProductTitleMobile = styled.div`
  font-size: 12px;
  font-family: 'LTUnivers-BasicRegular';
  font-weight: 100;
  margin: 0 0 0 0;
  line-height: 42px;
  color: black;
  height: 42px;
  width: 100%;
`

export const CardProductPriceMobile = styled.div`
  font-size: 12px;
  font-family: 'LTUnivers-BasicRegular';
  font-weight: 100;
  margin: 0 12px 0 0;
  line-height: 42px;
  color: black;
  height: 42px;
  width: 100%;
`

export const CheckoutButton = styled.div`
  background-color: #f2f2f2;
  margin: 30px 0 0 0;
  padding: 8px 20px 5px 20px;
  font-size: 12px;
  line-height: 42px;
  font-family: 'LTUnivers-BasicRegular';
  font-weight: 100;
  border: 1px solid black;
  width: max-content;
  -webkit-appearance: none;
`
export const CheckoutButtonMobile = styled.div`
  background-color: black;
  color: white;
  font-size: 14px;
  line-height: 42px;
  font-family: 'LTUnivers-BasicRegular';
  font-weight: 100;
  text-align: center;
  border: 1px solid black;
  width: 100%;
  -webkit-appearance: none;
`
export const RemoveSaveforLater = styled.div`
  color: lightgrey;
  font-size: 12px;
  line-height: 42px;
  font-family: 'LTUnivers-BasicRegular';
  text-align: right;
  padding: 280px 0 0 0;
`
export const RightMobile = styled.div`
  grid-column-start: 2;
  grid-column-end: 2;
  text-align: right;
`

export const LeftMobile = styled.div`
  grid-column-start: 1;
  grid-column-end: 1;
`
export const CenterMobile = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`

export const CartCounter = styled.span`
  z-index: 20;
  color: white;
  font-size: 12px;
  line-height: 42px;
  font-family: 'LTUnivers-BasicRegular';
  padding: 0 0 0 16px;
`

export const CartCounterMobile = styled.span`
  z-index: 20;
  color: white;
  font-size: 12px;
  line-height: 42px;
  font-family: 'LTUnivers-BasicRegular';
  padding: 0 0 0 16px;
`

export const CloseMobile = styled(Image)`
  position: fixed;
  top: 16px;
  right: 16px;
  width: 18px;
`

export const TouchPointMenu = styled.div`
  width: 70px;
  height: 10vh;
  background: transparent;
  position: fixed;
  top: 0;
  right: 0;
`
