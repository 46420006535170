import React, { useContext, useState, useEffect } from 'react'

import StoreContext from '~/context/StoreContext'
import {
  CardProductTitleMobile,
  CardProductText,
  LineItemTitle,
  LineItemOptions,
  LineItemPrice,
  Column1,
  Column2,
  Column3,
  Column4,
  Column5,
  CardProductButton,
  CardProductButtonMobile,
  CardProductTextMobile,
  LeftMobile,
  RightMobile,
  CardProductPriceMobile,
} from './styles'
import { BrowserView, MobileView } from 'react-device-detect'
import {
  GeneralGrid,
  GeneralGridMobile,
  GeneralGridMobileProduct,
} from '~/utils/styles'
import Arrow from '../../../images/down.png'

const countQuantity = lineItems => {
  let quantity = 0

  lineItems.forEach(item => {
    quantity = quantity + item.quantity
  })

  return quantity
}

const LineItem = props => {
  const { line_item } = props
  const {
    removeLineItem,
    addVariantToCart,
    updateLineItem,
    store: { client, checkout, adding },
  } = useContext(StoreContext)
  const [loading, setLoading] = useState(true)
  const [quantity, setQuantity] = useState(
    countQuantity(checkout ? checkout.lineItems : [])
  )

  const [openQty, setOpenQty] = useState(false)

  useEffect(() => {
    setQuantity(countQuantity(checkout ? checkout.lineItems : []))
  }, [checkout])

  const variantImage = line_item.variant.image ? (
    <img
      src={line_item.variant.image.src}
      alt={`${line_item.title} product shot`}
      style={{ width: '100%' }}
    />
  ) : null

  const selectedOptions = line_item.variant.selectedOptions
    ? line_item.variant.selectedOptions.map(
        option => `${option.name}: ${option.value} `
      )
    : null

  const handleAddToCart = quantity => {
    var checkoutID = localStorage.getItem('shopify_checkout_id')
    if (parseInt(quantity) === 0) {
      props.handleRemove(client, checkoutID, line_item.id, parseInt(quantity))
    }
    updateLineItem(client, checkoutID, line_item.id, parseInt(quantity))
    setOpenQty(false)
  }

  useEffect(() => {}, [adding, client, checkout.id, line_item.id])

  useEffect(() => {
    if (line_item) {
      setLoading(false)
    }
  }, [line_item])

  const getPrice = price =>
    Intl.NumberFormat(undefined, {
      currency: checkout.currencyCode ? checkout.currencyCode : 'USD',
      minimumFractionDigits: 2,
      style: 'currency',
    }).format(parseFloat(price ? price : 0))

  return (
    <div>
      {loading ? (
        <div>Loading</div>
      ) : (
        <div>
          <BrowserView>
            <GeneralGrid>
              <Column1>{variantImage}</Column1>

              <Column2>
                <LineItemTitle>
                  {line_item.title}
                  {`  `}
                  {line_item.variant.title === !'Default Title'
                    ? line_item.variant.title
                    : ''}
                </LineItemTitle>
              </Column2>
              <Column3>
                <LineItemOptions>
                  <CardProductText> {selectedOptions[0]}</CardProductText>
                  <CardProductText>
                    {' '}
                    {line_item.variant.selectedOptions[1].name}{' '}
                    <div
                      style={{
                        backgroundColor:
                          '#' + line_item.variant.selectedOptions[1].value,
                        borderRadius: '50%',
                        width: '12px',
                        height: '12px',
                        margin: '12px 10px 15px 8px',
                        lineHeight: '42px',
                      }}
                    ></div>
                  </CardProductText>
                  <CardProductText>
                    Quantity:{' '}
                    {openQty ? (
                      <select
                        style={{
                          border: 'none',
                          outline: 'none',
                          margin: '0 0 0 5px',
                        }}
                        value={line_item.quantity}
                        onChange={e => handleAddToCart(e.target.value)}
                      >
                        <option key={`${0}`}>{0}</option>
                        <option key={`${1}`}>{1}</option>
                        <option key={`${2}`}>{2}</option>
                        <option key={`${3}`}>{3}</option>
                        <option key={`${4}`}>{4}</option>
                        <option key={`${5}`}>{5}</option>
                      </select>
                    ) : (
                      <div style={{ margin: '0 0 0 5px' }}>
                        {line_item.quantity}
                        <img
                          onClick={() => setOpenQty(true)}
                          src={Arrow}
                          style={{
                            width: '12px',
                            margin: '0 0 0 10px',
                            cursor: 'pointer',
                          }}
                        />{' '}
                      </div>
                    )}
                    {/* <button
                      type="submit"
                      disabled={adding}
                      onClick={handleAddToCart}
                    >
                      Add to Cart
                    </button> */}
                  </CardProductText>
                  <CardProductButton
                    onClick={() =>
                      props.handleRemove(
                        client,
                        checkout.id,
                        line_item.id,
                        line_item.quantity
                      )
                    }
                  >
                    Remove
                  </CardProductButton>
                </LineItemOptions>{' '}
              </Column3>

              <Column5>
                <LineItemPrice>
                  USD{getPrice(line_item.variant.price)}
                </LineItemPrice>
              </Column5>
            </GeneralGrid>
          </BrowserView>

          <MobileView>
            {variantImage}
            <GeneralGridMobileProduct>
              <LeftMobile>
                <CardProductTitleMobile>
                  {line_item.title}
                  {`  `}
                  {line_item.variant.title === !'Default Title'
                    ? line_item.variant.title
                    : ''}
                </CardProductTitleMobile>
                <LineItemOptions>
                  <CardProductTextMobile style={{ margin: '45px 0 0 0' }}>
                    {line_item.variant.selectedOptions[1].name}{' '}
                    <div
                      style={{
                        backgroundColor:
                          '#' + line_item.variant.selectedOptions[1].value,
                        borderRadius: '50%',
                        width: '18px',
                        height: '18px',
                        margin: '14px 10px 15px 8px',
                        lineHeight: '42px',
                      }}
                    ></div>
                  </CardProductTextMobile>
                  <CardProductTextMobile>
                    {selectedOptions[0]}
                  </CardProductTextMobile>
                  <CardProductTextMobile>
                    Quantity:{' '}
                    {openQty ? (
                      <select
                        style={{
                          border: 'none',
                          outline: 'none',
                          margin: '0 0 0 5px',
                        }}
                        value={line_item.quantity}
                        onChange={e => handleAddToCart(e.target.value)}
                      >
                        <option key={`${0}`}>{0}</option>
                        <option key={`${1}`}>{1}</option>
                        <option key={`${2}`}>{2}</option>
                        <option key={`${3}`}>{3}</option>
                        <option key={`${4}`}>{4}</option>
                        <option key={`${5}`}>{5}</option>
                      </select>
                    ) : (
                      <div style={{ margin: '0 0 0 5px' }}>
                        {line_item.quantity}
                        <img
                          onClick={() => setOpenQty(true)}
                          src={Arrow}
                          style={{
                            width: '12px',
                            margin: '0 0 0 10px',
                            cursor: 'pointer',
                          }}
                        />{' '}
                      </div>
                    )}
                  </CardProductTextMobile>
                  <CardProductButtonMobile
                    onClick={() =>
                      props.handleRemove(
                        client,
                        checkout.id,
                        line_item.id,
                        line_item.quantity
                      )
                    }
                  >
                    <CardProductTitleMobile>Remove</CardProductTitleMobile>
                  </CardProductButtonMobile>
                </LineItemOptions>{' '}
              </LeftMobile>
              <RightMobile>
                <CardProductPriceMobile>
                  USD{getPrice(line_item.variant.price)}
                </CardProductPriceMobile>{' '}
              </RightMobile>
            </GeneralGridMobileProduct>
            <hr />
          </MobileView>
        </div>
      )}
    </div>
  )
}

export default LineItem
